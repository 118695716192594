// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

@import "~bootstrap-table/dist/bootstrap-table.min.css";
@import "~bootstrap-table/dist/extensions/reorder-rows/bootstrap-table-reorder-rows.css";

@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";

@import "~select2/dist/css/select2.min";
@import "~@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min";

@import "live";

@media (max-width: 576px) {
    h1 {
        font-size: 1.5rem;
    }
    h2 {
        font-size: 1.3rem;
    }
    h3 {
        font-size: 1.1rem;
    }
}

.form-control-decimal {
    min-width: 80px;
    text-align: right;
}

.form-control-number {
    min-width: 50px;
    text-align: right;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
// input[type="number"] {
//     -moz-appearance: textfield;
// }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  vertical-align: middle;
}

td.no-border {
    border: none;
}
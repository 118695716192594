.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: top;
}

.modal-container {
    width: 90%;
    margin: 30px auto;
    padding: 15px 5px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    position: relative;
}

@media (min-width: 768px) {
    .modal-container {
        width: 650px;
        padding: 20px 30px;
    }
}

.modal-header h3 {
    margin-top: 0;
    color: #42b983;
}

.modal-body {
    margin: 20px 0;
}

.modal-default-button {
    float: right;
}

/*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

.modal-enter-from {
    opacity: 0;
}

.modal-leave-to {
    opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
.modal-close-button {
    position: absolute;
    top: -17px;
    right: -17px;
    z-index: 9999;
    color: black;
    border-radius: 50%;
    background-color: #ccc;
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

tr.danger,
tr.danger td {
    background-color: #a02a2a;
    color: white;
}

tr.parcitipant-row:hover {
    background-color: #eee;
}

.table-nofluid {
    width: auto !important;
}
.nav-link {
    padding: .5rem;
}
@media (min-width: 768px) {
    .nav-link {
        padding: .5rem 1rem;
    }
}